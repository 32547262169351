import React from 'react';
import { ReactComponent as Logo } from './Goodr_tech_logo.svg';


function Home() {
    return (
        <header className='font-Montserrat'>
            <h1 className="text-6xl underline text-goodrgreen">Feed more.</h1>
            <h2 className="text-6xl text-goodrteal">Waste less.</h2>
            <section className="content">
                <p className="text-2xl">Goodr is a food rescue app that connects people with excess food to people in need.</p>
                <Logo className="w-32 h-32 fill-current flex-no-shrink logo" />
                <img className="image" src={process.env.PUBLIC_URL + '/Goodr_tech_logo.png'} alt="Goodr.tech logo" />
            </section>
        </header>
    )
}

export default Home;