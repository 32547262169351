import React from 'react'
import JsonData from '../data/talktome.json'

function JsonDataDisplay() {
    const DisplayData = JsonData.filter(d => d.status === 'active').map(
        (info) => {
            return (
                <tr>
                    <td className="font-bold">{info.name.nm_first} {info.name.nm_last}</td>
                    <td className="underline">{info.title}</td>
                    <td className="italic">{info.pronouns}</td>
                    <td>{info.team}</td>
                    <td className="text-2xl">
                        <div className="flex mb-4">
                            <span className="w-1/3">{info.communication_style[0]}</span>
                            <span className="w-1/3">{info.communication_style[1]}</span>
                            <span className="w-1/3">{info.communication_style[2]}</span>
                        </div>
                    </td>
                </tr>
            )
        }
    )

    return (
        <div>
            <table className="table text-sm table-striped">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Title</th>
                        <th>Pronouns</th>
                        <th>Team</th>
                        <th>Preferred Communication Style</th>
                    </tr>
                </thead>
                <tbody>


                    {DisplayData}

                </tbody>
            </table>

        </div>
    )
}

export default JsonDataDisplay;
