import React from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route
} from 'react-router-dom';
import Home from './components/home';
import About from './components/about';
import Contact from './components/contact';
import './styles/App.css';


function App() {
    return (
        <Router>
            <div className="container flex p-8 m-10 mx-auto text-teal-300 rounded-x">
                <nav className="flex flex-col h-screen p-5 pt-8 bg-dark-purple w-72">
                    <a
                        href="/"
                        className="flex items-center border-l-[3px] border-blue-500 bg-blue-50 px-4 py-3 text-blue-700 dark:bg-blue-500/20 dark:text-blue-50"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-5 h-5 opacity-75"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                            />
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                        </svg>

                        <span className="ml-3 text-sm font-medium"> Home </span>
                    </a>

                    <a
                        href="/contact"
                        className="flex items-center border-l-[3px] border-transparent px-4 py-3 text-gray-500 dark:text-gray-400 hover:border-gray-100 hover:bg-gray-50 hover:text-gray-700 dark:hover:border-gray-700 dark:hover:bg-gray-800 dark:hover:text-gray-200"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-5 h-5 opacity-75"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                            />
                        </svg>

                        <span className="ml-3 text-sm font-medium"> Teams </span>
                    </a>

                    <a
                        href="/"
                        className="flex items-center border-l-[3px] border-transparent px-4 py-3 text-gray-500 dark:text-gray-400 hover:border-gray-100 hover:bg-gray-50 hover:text-gray-700 dark:hover:border-gray-700 dark:hover:bg-gray-800 dark:hover:text-gray-200"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-5 h-5 opacity-75"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
                            />
                        </svg>

                        <span className="ml-3 text-sm font-medium"> Billing </span>
                    </a>

                    <a
                        href="/"
                        className="flex items-center border-l-[3px] border-transparent px-4 py-3 text-gray-500 dark:text-gray-400 hover:border-gray-100 hover:bg-gray-50 hover:text-gray-700 dark:hover:border-gray-700 dark:hover:bg-gray-800 dark:hover:text-gray-200"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-5 h-5 opacity-75"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M9 7h6m0 10v-3m-3 3h.01M9 17h.01M9 14h.01M12 14h.01M15 11h.01M12 11h.01M9 11h.01M7 21h10a2 2 0 002-2V5a2 2 0 00-2-2H7a2 2 0 00-2 2v14a2 2 0 002 2z"
                            />
                        </svg>

                        <span className="ml-3 text-sm font-medium"> Invoices </span>
                    </a>

                    <a
                        href="/"
                        className="flex items-center border-l-[3px] border-transparent px-4 py-3 text-gray-500 dark:text-gray-400 hover:border-gray-100 hover:bg-gray-50 hover:text-gray-700 dark:hover:border-gray-700 dark:hover:bg-gray-800 dark:hover:text-gray-200"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-5 h-5 opacity-75"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                            />
                        </svg>

                        <span className="ml-3 text-sm font-medium"> Account </span>
                    </a>
                </nav>

                <Routes>
                    <Route path='/' element={< Home />}></Route>
                    <Route path='/about' element={< About />}></Route>
                    <Route path='/contact' element={< Contact />}></Route>
                </Routes>
            </div>
        </Router>
    );
}

export default App;
