import React from 'react';

function About() {
    return (

        <header>
            <h1 className="green animate-underline">Feed more.</h1>
            <h2 className="darkgreen">Waste less.</h2>
        </header>
    )
}

export default About;