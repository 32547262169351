import React from 'react';
import JsonDataDisplay from './TeamTable'


function Contact() {
    return (
        <div className="talktome">
            <h1>Talk to Me</h1>
            <JsonDataDisplay />
        </div>
    )
}

export default Contact;